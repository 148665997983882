import {Form} from 'react-final-form'
import {FieldValidator, FORM_ERROR} from 'final-form'

import Button from 'src/components/Button'
import {signIn} from 'src/gs-api'
import {ErrorMessage} from 'src/components/form/input'
import useViewer from 'src/hooks/useViewer'
import {composeValidators, required, validEmail} from 'src/form/validation'

import TextField from './form/TextField'
import {Flex, Box} from './Box'

interface FormValues {
  password: string
  email: string
}

const SignInForm = () => {
  const {refetch} = useViewer()

  const onSubmit = ({password, email}: FormValues) =>
    signIn({
      student: {
        password,
        email,
      },
    }).then((result) => {
      if ('error' in result) {
        return {[FORM_ERROR]: result.error}
      }
      refetch()
    })

  return (
    <Form
      initialValues={{
        password: '',
        email: '',
      }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        submitError,
        submitting,
        pristine,
        valid,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Flex flexDirection="column">
            <TextField
              validate={
                composeValidators(
                  required,
                  validEmail
                ) as FieldValidator<string>
              }
              label="Email"
              name="email"
              type="email"
            />
            <Box>
              <TextField
                validate={required}
                label="Password"
                name="password"
                type="password"
              />
            </Box>

            {submitError && <ErrorMessage>{submitError}</ErrorMessage>}

            <Flex flexDirection="column" mt={3}>
              <Button
                busy={submitting}
                disabled={
                  submitting || pristine || (!valid && !dirtySinceLastSubmit)
                }
                type="submit"
              >
                Log in
              </Button>
            </Flex>
          </Flex>
        </form>
      )}
    </Form>
  )
}

export default SignInForm
