import React, {useEffect} from 'react'
import {Link, PageRendererProps, navigate} from 'gatsby'
import sample from 'lodash/sample'
import {usePrevious} from 'react-use'

import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import SignInForm from 'src/components/SignInForm'
import {Flex, Box} from 'src/components/Box'
import {Header1} from 'src/components/text'
import useViewer from 'src/hooks/useViewer'
import {useToastAction} from 'src/context/ToastContext'
import {toastWithHeading} from 'src/components/ToastTemplates'
import {localPaths} from 'src/urls'
import {useConfettiCannon} from 'src/context/ConfettiContext'
import GoogleSsoButton from 'src/components/GoogleSsoButton'

const WELCOME_BACK_TOASTS = [
  toastWithHeading({
    heading: 'Welcome back',
    emoji: {
      emoji: '🎉',
      label: 'confetti popper',
    },
    text: 'Nice progress! Pick back up where you left off.',
  }),
  toastWithHeading({
    heading: 'Hello again',
    emoji: {
      emoji: '👧',
      label: `girl's face`,
    },
    text: 'Nice to have you back, which goal will you accomplish today?',
  }),
  toastWithHeading({
    heading: 'Oh hey',
    emoji: {
      emoji: '👋',
      label: 'waving hand',
    },
    text: 'You seriously are doing great! Keep at those #goals.',
  }),
  toastWithHeading({
    heading: 'You Rock',
    emoji: {
      emoji: '👽',
      label: 'alien face',
    },
    text: 'Way to go so far! You got this!!',
  }),
  toastWithHeading({
    heading: 'Keep Going',
    emoji: {
      emoji: '👊',
      label: 'fist bump',
    },
    text: 'We see you working toward your goals!',
  }),
] as const

const getWelcomeBackToast = () =>
  sample(WELCOME_BACK_TOASTS) || WELCOME_BACK_TOASTS[0]

const SignInPage = ({location}: PageRendererProps) => {
  const viewerResult = useViewer()
  const {fireConfetti} = useConfettiCannon()
  const prevViewer = usePrevious(viewerResult.viewer)
  const {addToast} = useToastAction()

  let redirectOnLogin =
    (location.state as {returnTo?: string})?.returnTo ?? localPaths.dashboard

  const params = new URLSearchParams(location.search)
  const redirectFrom = params.get('redirect_from')

  if (redirectFrom === 'interviewcoach') {
    redirectOnLogin = '/interviewcoach'
  }

  if (viewerResult.loggedIn === false || redirectFrom === 'interviewcoach') {
    if (typeof window !== 'undefined') {
      localStorage.setItem('redirectUrl', redirectOnLogin)
    }
  }

  // If the user has returned after a certain amount of time then we want to
  // show a special message.
  useEffect(() => {
    if (viewerResult.loggedIn && prevViewer === 'not-logged-in') {
      const {daysSinceLastSignIn} = viewerResult.viewer.student

      if (daysSinceLastSignIn >= 1) {
        addToast({
          body: getWelcomeBackToast(),
        })

        fireConfetti(true)
      }
    }
  }, [addToast, fireConfetti, prevViewer, viewerResult])

  // If the user has logged in (or was already logged in) then we want to send
  // them away from the login page.
  useEffect(() => {
    if (!viewerResult.loggedIn) {
      return
    }

    if (typeof window !== 'undefined') {
      let redirectPath = localStorage.getItem('redirectUrl') || redirectOnLogin

      if (redirectPath.includes('sign-up') || redirectPath.includes('log-in')) {
        redirectPath = localPaths.dashboard
      }

      navigate(redirectPath)
    } else {
      navigate(redirectOnLogin)
    }
  }, [addToast, prevViewer, redirectOnLogin, viewerResult.loggedIn])

  return (
    <Layout
      narrow
      loadingContent={viewerResult.loading || viewerResult.loggedIn}
    >
      <SEO title="Log In" />
      <Box pt={4}>
        <Header1>Log in</Header1>
      </Box>

      <Flex mt={3} flexDirection="column">
        <SignInForm />
        <Flex mt={1} flexDirection="column">
          <GoogleSsoButton
            buttonText="Sign in with Google"
            redirectLink="/v2/auth/google_oauth2"
          />
        </Flex>
        <Flex flexDirection="column" alignItems="center" mt={3}>
          <Link to={localPaths.resetPassword}>Help, I forgot my password</Link>
        </Flex>
        <Flex flexDirection="column" alignItems="center" mt={3}>
          <Link to={localPaths.signUp} state={{returnTo: redirectOnLogin}}>
            Create account
          </Link>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default SignInPage
